<template>
  <div class="page-auth">
      <v-container>
        <v-row align="center"
               class="pt-10"
               justify="center">

          <v-col cols="11"
                 lg="4"
                 md="4"
                 sm="8">

            <v-card flat>
              <v-card-text class="pa-6">

                <div class="text-center">
                  <img :src="require('@/assets/logo.jpg')"
                       alt="logo"
                       width="120">
                </div>

                <p class="py-3 text-center fs-14">
                  Connectez-vous pour continuer.
                </p>

                <v-alert v-if="errors && errors.invalid"
                         border="left"
                         class="rounded-lg"
                         color="red"
                         dark
                         dense
                         dismissible
                         icon="mdi-alert"
                         prominent>
                  {{ errors && errors.invalid ? errors.invalid : '' }}
                </v-alert>


                <form @submit.prevent="login">

                  <p class="font-weight-medium mb-2">Email *</p>
                  <v-text-field v-model="form.email"
                                :error-messages="errors.email"
                                class="inp"
                                filled
                                rounded
                                placeholder="Votre e-mail"
                                prepend-inner-icon="mdi-email-outline"
                                type="email"
                                @input='errors && errors.email ? errors.email= "": false'></v-text-field>

                  <p class="font-weight-medium mb-2">Mot de passe *</p>
                  <v-text-field v-model="form.password"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :error-messages="errors.password"
                                :type="show ? 'text' : 'password'"
                                class="inp"
                                filled
                                rounded
                                placeholder="Votre mot de passe"
                                prepend-inner-icon="mdi-lock-outline"
                                @input='errors && errors.password ? errors.password= "": false'
                                @click:append="show = !show"></v-text-field>



                  <v-btn :loading="btnLoading"
                         :disabled="btnLoading"
                         block
                         color="primary"
                         depressed
                         large
                         rounded
                         type="submit">
                    Connexion
                  </v-btn>
                </form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {

    data() {
        return {
            btnLoading: false,
            errors: {},
            form: {},
            show: false
        }
    },
    methods: {
        login() {
            this.btnLoading = true
            this.errors = {}
            axios.post(process.env.VUE_APP_BASE_URL + '/api/login', this.form).then((res) => {
                this.$store.dispatch('isLoggedIn', true)
                this.$store.dispatch('user', res.data.data)
                this.btnLoading = false
                this.$router.go(0);
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors;
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                console.log(err)
            })
        }
    }

}
</script>

<style>
.page-auth {
    /*background: url('../../assets/auth.jpg');*/
    background: white;
    min-height: 100vh;
}
</style>
